import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero/Hero'
import SEO from '../components/SEO'

export const PageTemplate = ({ title, content, subtitle }) => (
  <div>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="page-intro has-text-centered">
              <h1 className="subtitle is-size-2 is-size-3-mobile">{title}</h1>
              <p className="is-size-4 is-size-5-mobile">{subtitle}</p>
              <hr />
              <section
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data

  return (
    <Layout color={page.acf.header_type} slug={page.slug}>
      <SEO title={page.title} />
      <PageTemplate
        title={page.title}
        subtitle={page.acf.subtitle}
        content={page.content}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      acf {
        header_type
        subtitle
      }
    }
  }
`
